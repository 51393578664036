import { Controller } from "@hotwired/stimulus";
import detectLocale from "../util/detectLocale";

const LAT_LNG_MAX_PRECISION = 6;
export class MapkitMap extends Controller {
  static initialized = false;

  static values = {
    latitude: Number,
    longitude: Number,
    address: String,
    labelColor: String,
    tokenServer: String,
    draggable: Boolean,
    reverseGeocodeUrl: String,
  };

  static targets = ["latitude", "longitude", "address", "map"];

  declare latitudeValue: number | undefined | null;
  declare longitudeValue: number | undefined | null;
  declare addressValue: string | undefined | null;
  declare labelColorValue: string | undefined | null;
  declare tokenServerValue: string | undefined | null;
  declare draggableValue: boolean | undefined | null;
  declare reverseGeocodeUrlValue: string | undefined | null;

  declare readonly latitudeTarget: HTMLInputElement;
  declare readonly longitudeTarget: HTMLInputElement;
  declare readonly addressTargets: HTMLInputElement[];
  declare readonly mapTarget: HTMLElement;
  declare readonly hasLatitudeTarget: boolean;
  declare readonly hasLongitudeTarget: boolean;
  declare readonly hasAddressTarget: boolean;
  declare readonly hasMapTarget: boolean;

  authorizeMap(onComplete: () => void) {
    if (!window.mapkit) {
      console.error("Mapkit not loaded, exiting");
      return;
    }
    if (MapkitMap.initialized) {
      return onComplete();
    }
    const tokenServer = this.tokenServerValue;
    if (typeof tokenServer !== "string") {
      console.error("No token server specified, cannot authenticate");
      return;
    }
    mapkit.init({
      authorizationCallback: function (done: (token: string) => void) {
        void fetch(tokenServer, {
          method: "POST",
        }).then((result) => {
          if (result.status === 201) {
            void result.text().then((txt) => {
              done(txt.trim());
              onComplete();
              MapkitMap.initialized = true;
            });
          }
        });
      },
    });
  }

  defaultTitleForLatLng(latitude: number, longitude: number) {
    return `${latitude.toPrecision(
      LAT_LNG_MAX_PRECISION
    )}, ${longitude.toPrecision(LAT_LNG_MAX_PRECISION)}`;
  }

  newMarker(
    latitude: number,
    longitude: number,
    labelColor: string,
    draggable: boolean,
    address?: string | null
  ) {
    const coordinate = new mapkit.Coordinate(latitude, longitude);
    const marker = new mapkit.MarkerAnnotation(coordinate, {
      color: labelColor,
      title: address ?? this.defaultTitleForLatLng(latitude, longitude),
      draggable,
    });
    if (draggable) {
      marker.addEventListener("drag-end", (event) => {
        const { coordinate } = event.target;
        const { latitude, longitude } = coordinate;
        const address = this.defaultTitleForLatLng(latitude, longitude);
        marker.title = address;
        this.latitudeValue = latitude;
        this.longitudeValue = longitude;
        this.addressValue = address;
        if (this.hasLatitudeTarget) {
          this.latitudeTarget.value = latitude.toString();
        }
        if (this.hasLongitudeTarget) {
          this.longitudeTarget.value = longitude.toString();
        }
        if (this.hasAddressTarget) {
          for (const addressTarget of this.addressTargets) {
            addressTarget.value = address;
          }
        }
        this.updateAddress(latitude, longitude).then((result) => {
          const defaultTitle = this.defaultTitleForLatLng(latitude, longitude);
          const [isValid, newAddress] = result;
          this.addressValue = newAddress ?? defaultTitle;
          marker.title = newAddress ?? defaultTitle;
          if (isValid) {
            if (this.hasAddressTarget) {
              for (const addressTarget of this.addressTargets) {
                addressTarget.value = newAddress ?? defaultTitle;
                addressTarget.placeholder = "";
              }
            }
          } else {
            if (this.hasAddressTarget) {
              for (const addressTarget of this.addressTargets) {
                addressTarget.value = "";
                if (addressTarget.type === "text") {
                  addressTarget.placeholder = newAddress ?? "";
                }
              }
            }
            this.latitudeValue = null;
            this.longitudeValue = null;
            if (this.hasLatitudeTarget) {
              this.latitudeTarget.value = "";
            }
            if (this.hasLongitudeTarget) {
              this.longitudeTarget.value = "";
            }
          }
        });
      });
    }
    return marker;
  }

  async updateAddress(
    latitude: number,
    longitude: number
  ): Promise<[boolean, string | null]> {
    let address: string | null = null;
    let valid = true;
    if (typeof this.reverseGeocodeUrlValue === "string") {
      const params = new URLSearchParams({
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      });
      const result = await fetch(
        this.reverseGeocodeUrlValue + "?" + params,
        {}
      );
      // The >= 500 case is our bias to say that if the reverse geocoding service
      // is unavailable, we *presume* valid and let the operation continue as if it
      // would have been valid. Later checks in the process will also ensure that
      // invalid locations will be corrected.
      valid = result.status === 200 || result.status >= 500;
      if (result.status === 200 || result.status === 412) {
        const asJson = await result.json();
        address = asJson.address as string | null;
      }
    }
    if (valid && address === null) {
      // We could not ascertain a server result, and still think it's valid. Fall back to mapkit.
      const geocoder = new mapkit.Geocoder({
        language: detectLocale(),
        getsUserLocation: false,
      });
      const promise = new Promise<string | null>((resolve, reject) => {
        geocoder.reverseLookup(
          new mapkit.Coordinate(latitude, longitude),
          (error, data) => {
            if (error) {
              return reject(error);
            }
            if (Array.isArray(data?.results) && data.results.length >= 1) {
              const address = data.results[0]?.formattedAddress;
              if (typeof address === "string") {
                return resolve(address);
              }
            }
            return resolve(null);
          }
        );
      });
      address = await promise;
    }
    return [valid, address];
  }

  initializeMap() {
    if (!window.mapkit) {
      console.error("Mapkit not loaded, exiting");
      return;
    }
    const mapElement = this.hasMapTarget ? this.mapTarget : this.element;
    const map = new mapkit.Map(mapElement);
    const latitudeValue = this.latitudeValue;
    const longitudeValue = this.longitudeValue;
    const addressValue = this.addressValue;
    const labelColorValue = this.labelColorValue;
    const draggable = this.draggableValue ?? false;
    const labelColor = labelColorValue ?? "#f4a56d";
    if (
      typeof latitudeValue === "number" &&
      typeof longitudeValue === "number"
    ) {
      map.showItems([
        this.newMarker(
          latitudeValue,
          longitudeValue,
          labelColor,
          draggable,
          addressValue
        ),
      ]);
      mapElement.addEventListener("map:change", (evt) => {
        const event = evt as CustomEvent;
        if (!event.detail) {
          return;
        }
        const { address, latitude, longitude } = event.detail;
        if (
          typeof address !== "string" ||
          typeof latitude !== "number" ||
          typeof longitude !== "number"
        ) {
          return;
        }
        this.latitudeValue = latitude;
        this.longitudeValue = longitude;
        this.addressValue = address;
        map.removeAnnotations(map.annotations);
        map.showItems([
          this.newMarker(latitude, longitude, labelColor, draggable, address),
        ]);
      });
    }
  }

  connect() {
    if (!window.mapkit) {
      const scriptNode = document.createElement("script");
      scriptNode.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";
      scriptNode.type = "text/javascript";
      scriptNode.defer = true;
      scriptNode.addEventListener("load", () =>
        this.authorizeMap(() => this.initializeMap())
      );
      document.body.appendChild(scriptNode);
    } else {
      this.authorizeMap(() => this.initializeMap());
    }
  }
}
